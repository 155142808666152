
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref
} from "vue";

import { months } from "@/utils";

//@ts-ignore
import ScrollPicker from "vue3-scroll-picker";
import MButton from "@/components/ui/mobile/buttons/MButton.vue";

export default defineComponent({
  name: "MMonthPicker",
  components: { MButton, ScrollPicker },
  emits: ["close", "update:date"],
  props: {
    date: { type: Object as PropType<Date>, required: true }
  },

  setup(props, { emit }) {
    const years = [...new Array(11).keys()].map(item => ({
      label: 2021 + (item - 5),
      value: 2021 + (item - 5)
    }));

    const options = [months.map((label, value) => ({ label, value })), years];
    const selection = ref([props.date.getMonth(), props.date.getFullYear()]);
    const selectDate = () => {
      emit("update:date", new Date(selection.value[1], selection.value[0], 1));
      emit("close");
    };

    onMounted(() => {
      document.body.style.overflow = "hidden";
    });

    onUnmounted(() => {
      document.body.style.overflow = "auto";
    });

    const disabled = computed(() => {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();

      const [selectedMonth, selectedYear] = selection.value;

      const availableMonth =
        selectedYear < currentYear ? false : selectedMonth > currentMonth;

      return availableMonth || selectedYear > currentYear;
    });

    return { options, selection, selectDate, disabled };
  }
});
