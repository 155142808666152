
import { computed, defineComponent } from "vue";
import { keys } from "lodash";

import { useTimesheet } from "@/store/timesheet.store";
import { formatDateString, formatTimeString } from "@/utils";

import PCard from "@/components/PCard.vue";
import PVisitType from "@/components/PVisitType.vue";

export default defineComponent({
  name: "PVisit",
  components: { PVisitType, PCard },
  props: { flat: Boolean },

  setup(props) {
    const timesheetStore = useTimesheet();

    const formatted = computed(() => {
      return keys(timesheetStore.timesheet.value?.items.timeSheet)
        .sort()
        .reverse();
    });

    const getSheet = (dateString: string) => {
      return timesheetStore.timesheet.value?.items.timeSheet[dateString];
    };

    return {
      props,
      formatDateString,
      formatTimeString,
      formatted,
      getSheet,
      timesheet: timesheetStore,
      isFreeDay: timesheetStore.isFreeDay
    };
  }
});
