
import { defineComponent } from "vue";
import PCard from "@/components/PCard.vue";
import UiSkeletonTitle from "@/components/ui/skeletons/UiSkeletonTitle.vue";
import UiSkeletonSubtitle from "@/components/ui/skeletons/UiSkeletonSubtitle.vue";
import PWeek from "@/components/calendar/PWeek.vue";
import PDay from "@/components/calendar/PDay.vue";
import UiSkeletonDay from "@/components/ui/skeletons/UiSkeletonDay.vue";
import UiSkeletonText from "@/components/ui/skeletons/UiSkeletonText.vue";

export default defineComponent({
  name: "UiSkeletonCalendar",
  components: {
    UiSkeletonText,
    UiSkeletonDay,
    PDay,
    PWeek,
    UiSkeletonSubtitle,
    UiSkeletonTitle,
    PCard
  }
});
