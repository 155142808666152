import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { dateToString } from "@/utils";
import { useTimesheet } from "@/store/timesheet.store";

export function useAttendancePage() {
  const date = ref(new Date());
  const route = useRoute();

  const userId = computed(() => route.params.id?.toString());
  const dateString = computed(() => dateToString(date.value));

  const timesheet = useTimesheet();

  function loadTimesheet() {
    return timesheet.loadTimesheet(userId.value, dateString.value);
  }

  watch(
    () => route.params,
    () => loadTimesheet(),
    { immediate: true }
  );

  watch(
    () => date.value,
    () => loadTimesheet()
  );

  return {
    date,
    route
  };
}
