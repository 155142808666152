
import { computed, defineComponent, PropType } from "vue";
import { isNull } from "lodash";

import { useItemTypes } from "@/store/itemTypes.store";
import { TimesheetType } from "@/types/Timesheet";
import { getItemTypeBackground } from "@/utils/itemTypes";

export default defineComponent({
  name: "PVisitType",

  props: {
    data: { type: Object as PropType<TimesheetType>, required: true }
  },

  setup(props) {
    const itemTypes = useItemTypes();

    const empty = computed(() => {
      return isNull(props.data);
    });

    const present = computed(() => {
      return props.data.present;
    });

    const missing = computed(() => {
      return !props.data.present && isNull(props.data.timeSheetItemTypeId);
    });

    const text = computed(() => {
      if (empty.value) return "Не учился";
      else if (present.value) return "Зарегистрирован";
      else if (missing.value) return "Без причины";
      else return itemTypes.getName(props.data.timeSheetItemTypeId!);
    });

    const background = computed(() => {
      if (empty.value) {
        return getItemTypeBackground("NULL");
      } else if (present.value) {
        return getItemTypeBackground("ЗАРЕГИСТРИРОВАН");
      } else if (missing.value) {
        return getItemTypeBackground("БЕЗ ПРИЧИНЫ");
      } else {
        const name = itemTypes.getName(props.data.timeSheetItemTypeId!);
        return getItemTypeBackground(name);
      }
    });

    return { props, text, background };
  }
});
