
import { defineComponent, ref } from "vue";

import PCalendar from "@/components/calendar/PCalendar.vue";
import PVisit from "@/components/PVisit.vue";
import PKidList from "@/components/kids/PKidList.vue";
import MBottomNavigation from "@/components/ui/mobile/bottom-navigation/MNavigation.vue";
import MAppBarCompact from "@/components/ui/mobile/app-bar/MAppBarCompact.vue";
import UiSkeletonCalendar from "@/components/ui/skeletons/UiSkeletonCalendar.vue";
import MMonthPicker from "@/components/ui/mobile/MMonthPicker.vue";
import MDividerHoriz from "@/components/ui/mobile/divider/MDividerHoriz.vue";
import MApp from "@/components/ui/mobile/app/MApp.vue";
import MKidList from "@/components/mobile/MKidList.vue";
import { useAttendancePage } from "@/composable/useAttendancePage";
import { useModal } from "@/composable/useModal";
import MIconButton from "@/components/ui/mobile/buttons/MIconButton.vue";

export default defineComponent({
  name: "Attendance",
  components: {
    MIconButton,
    MKidList,
    MApp,
    MDividerHoriz,
    MMonthPicker,
    UiSkeletonCalendar,
    MAppBarCompact,
    PCalendar,
    PVisit,
    PKidList,
    MBottomNavigation
  },

  setup() {
    const attendancePage = useAttendancePage();
    const datepicker = useModal();

    return {
      date: attendancePage.date,
      route: attendancePage.route,
      datepicker
    };
  }
});
